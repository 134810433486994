import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";
import FaqPage from "../components/FAQ/FaqPage";

const FAQ = () => {
  return (
    <Root>
      <SEO title="FAQ" />
      <FaqPage />
    </Root>
  );
};

export default FAQ;
