import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {},
  section: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  gridItemVideo: {
    marginBottom: theme.spacing(12),
  },
  mainTitle: {
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "5rem",
    },
  },
  startNowButton: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.25rem",
      fontWeight: "500",
    },
  },
}));

const FAQPage = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <Typography className={classes.mainTitle} variant="h2">
                  FAQ
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ margin: "10px auto", maxWidth: 600, textAlign: "center" }}>
                <Typography variant="h6">
                  We're working hard to update this page. If you have any questions, send us an email at{" "}
                  <a href="mailto:contact@app-mockup.com">contact@app-mockup.com</a>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className={classes.section}>
        <Container maxWidth="lg">
          <Grid container spacing={0}></Grid>
        </Container>
      </div>
    </div>
  );
};

FAQPage.propTypes = {};

export default FAQPage;
